<template>
  <section class="listar-tomador pr-4">
    <div class="m-1 mr-4 px-0">
      <breadcrumb title="Tomadores" actualPage="Lista de tomadores" />
    </div>

    <div
      class="float-left flex mt-8 mb-10 gap-3 w-full md:w-4/5 lg:w-3/5 lp:w-1/2"
    >
      <filtro ref="filtro" :titleName="'Tomador'" :isFilter="filtro">
        <div class="pt-2 pb-6 px-4">
          <div class="flex flex-col mt-4 md:mt-8 md:flex-row">
            <vs-input
              size="large"
              label-placeholder="Nome do Tomador"
              class="w-full mt-4 md:w-3/5 md:pr-3 md:m-0"
              v-model="filter.policyHolderName"
              @keyup.enter="listarTomadores(true)"
            />
            <vs-input
              size="large"
              label-placeholder="CNPJ Tomador"
              class="w-full mt-8 md:w-2/5 md:m-0"
              v-model="filter.policyHolderDocument"
              @keyup.enter="listarTomadores(true)"
            />
          </div>

          <div class="flex flex-row-reverse mt-4">
            <vs-button
              @click="listarTomadores(true)"
              color="primary"
              type="filled"
              class="ml-4"
              >Aplicar filtro</vs-button
            >
            <vs-button
              color="primary"
              type="filled"
              icon="clear"
              @click="limparFiltros()"
              >Limpar filtro</vs-button
            >
          </div>
        </div>
      </filtro>
      <Exportar
        v-if="profileHelpers.isInsurance()"
        :Type="1"
        :Label="'Tomadores'"
      />
    </div>

    <vs-popup
      title="Você ainda tem documentos pendentes e/ou aguardando análise pela seguradora "
      :active.sync="modalDocuments"
      class="popup-width-auto"
      style="word-wrap: break-word;"
    >
      <vs-button
        router-link
        to="/corretor"
        class="w-full sm:w-7/12 sm:float-right"
        @click="modalDocuments = false"
      >
        Ir para dados da corretora
      </vs-button>
    </vs-popup>

    <div class="table-content pt-2">
      <template v-if="tomadores.length > 0">
        <vs-table
          class="table-list"
          noDataText="Nenhum tomador encontrado"
          :data="tomadores || []"
          :hoverFlat="true"
        >
          <template slot="thead">
            <vs-th class="text-center">Ações</vs-th>
            <vs-th>CNPJ</vs-th>
            <vs-th>Nome</vs-th>
            <vs-th>Limite geral</vs-th>
            <vs-th>Limite disponível</vs-th>
            <vs-th>Data bloqueio</vs-th>
            <vs-th>Data Bloqueio Restritivo</vs-th>
            <vs-th>Ignorar CCG</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr
              :data="tr"
              :state="
                data[indextr].PolicyHolderIsBlocked === true ||
                data[indextr].PolicyHolderIsBlockedRestrictive === true
                  ? 'danger'
                  : null
              "
              :key="indextr"
              v-for="(tr, indextr) in data"
            >
              <vs-td
                :data="data[indextr].PolicyHolderUniqueId"
                class="text-center"
              >
                <vs-dropdown
                  vs-custom-content
                  vs-trigger-click
                  class="cursor-pointer"
                >
                  <vs-button color="dark" type="flat" title="Ações">
                    <i class="onpoint-dots-three-vertical icon-dots-three-vertical" role="button"></i>
                  </vs-button>
                  <vs-dropdown-menu style="width: 200px">
                    <vs-dropdown-item
                      @click="
                        goToPolicyHolderDetail(
                          data[indextr].PolicyHolderUniqueId
                        )
                      "
                    >
                      <p class="text-base px-6 py-2">
                        <span>Visualizar</span>
                      </p>
                    </vs-dropdown-item>
                    <vs-dropdown-item
                      v-if="
                        profileHelpers.checkPermission([
                          profileHelpers.menuFuncionalitiesEnum
                            .GERENCIAR_TOMADORES_SEGURADORA
                        ])
                      "
                      @click="
                        openModalBlock(
                          data[indextr].PolicyHolderId,
                          data[indextr].PolicyHolderIsBlocked
                        )
                      "
                    >
                      <p class="text-base px-6 py-2">
                        <span>{{
                          !data[indextr].PolicyHolderIsBlocked
                            ? "Bloquear"
                            : "Desbloquear"
                        }}</span>
                      </p>
                    </vs-dropdown-item>
                  </vs-dropdown-menu>
                </vs-dropdown>
              </vs-td>

              <vs-td
                :data="data[indextr].PolicyHolderDocument"
                class="text-center"
              >
                <span class="font-semibold">
                  {{ data[indextr].PolicyHolderDocument | maskCpfCnpj }}
                </span>
              </vs-td>

              <vs-td :data="data[indextr].PolicyHolderName">
                <span class="font-semibold">{{
                  data[indextr].PolicyHolderName
                }}</span>
              </vs-td>

              <vs-td :data="data[indextr].ApprovedLimit" class="text-center">
                <span class="font-semibold">{{
                  data[indextr].ApprovedLimit | maskCurrency
                }}</span>
              </vs-td>

              <vs-td :data="data[indextr].AvailableLimit" class="text-center">
                <span class="font-semibold">{{
                  data[indextr].AvailableLimit | maskCurrency
                }}</span>
              </vs-td>

              <vs-td>
                <span
                  class="font-semibold"
                  v-if="data[indextr].PolicyHolderIsBlocked"
                >
                  {{
                    data[indextr].PolicyHolderBlockDate | moment("DD/MM/YYYY")
                  }}
                </span>
              </vs-td>

              <vs-td>
                <span
                  class="font-semibold"
                  v-if="data[indextr].PolicyHolderIsBlockedRestrictive"
                  >{{
                    data[indextr].PolicyHolderBlockRestrictiveDate
                      | moment("DD/MM/YYYY")
                  }}
                </span>
              </vs-td>
              <vs-td>
                <span class="font-semibold" v-if="data[indextr]"
                  >{{ data[indextr].IgnoreCCGSignature ? "SIM" : "NÃO" }}
                </span>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>

        <vs-pagination
          class="mt-5"
          :total="numberOfPages"
          v-model="currentPage"
        ></vs-pagination>
      </template>

      <template v-else>
        <div class="flex flex-col w-full md:w-1/2 mx-auto text-center mt-32">
          <p><span class="material-icons text-6xl">description</span></p>
          <p>Você ainda não possui nenhum tomador cadastrado.</p>
        </div>
      </template>
    </div>

    <vs-popup
      class="validacao-data-vigente-modal"
      :title="modalTitle"
      :active.sync="openModalBlockPoliceHolder"
    >
      <div class="vw-row mt-3">
        <div class="vx-col pt-5 w-full mt-2" v-if="!isBlocked">
          <label class="font-semibold">Justificativa</label>
          <vs-textarea v-model="justifyBlock" />
        </div>
        <div class="vx-col w-full text-right">
          <vs-button @click="closeModalBlock()" class="button-default mr-5">
            Cancelar
          </vs-button>
          <vs-button @click="blockPoliceHolder()" class="button-primary">
            Confirmar
          </vs-button>
        </div>
      </div>
    </vs-popup>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import * as Utils from "@/utils/utils";
import { mask, TheMask } from "vue-the-mask";
import Breadcrumb from "@/layouts/components/breadcrumb/Breadcrumb.vue";
import Filtro from "@/components/Filtro/Filtro";
import Exportar from "@/components/exportar-dados/index.vue";
import PerfilHelpers from "@/helpers/PerfilHelpers";

export default {
  components: { TheMask, Breadcrumb, Filtro, Exportar },
  directives: { mask },
  data() {
    return {
      modalDocuments: false,
      currentPage: 1,
      rowsPerPage: 10,
      filter: {
        policyHolderDocument: "",
        policyHolderName: ""
      },
      openModalBlockPoliceHolder: false,
      policyHolderUniqueId: null,
      modalTitle: "",
      justifyBlock: "",
      isBlocked: false,
      filtro: false,
      profileHelpers: PerfilHelpers
    };
  },
  async mounted() {
    await this.$onpoint.loading(async () => {
      await this.carregarCorretora();
    });
  },
  created() {
    this.$appInsights.trackPageView({
      name: "tomadores-listar",
      url: window.location.href
    });
  },
  computed: {
    ...mapGetters("cadastro-corretora", ["bDocumentacaoPendente"]),
    ...mapGetters("tomador-module", ["searchTomadoresResult", "tomadores"]),
    numberOfPages: {
      get() {
        return (this.searchTomadoresResult || {}).NumberOfPages || 0;
      }
    }
  },
  watch: {
    currentPage(newVal, oldVal) {
      this.listarTomadores();
    }
  },
  methods: {
    ...mapActions("tomador-module", [
      "searchTomadores",
      "blockUnblockPolicyHolder"
    ]),
    ...mapActions("cadastro-corretora", ["getCorretora"]),

    goToPolicyHolderDetail(PolicyHolderUniqueId) {
      this.$router.push({
        name: "detalhe-tomador",
        params: {
          tomadorUniqueId: PolicyHolderUniqueId
        }
      });
    },

    openModalBlock(policyHolderId, isBlocked) {
      this.modalTitle = isBlocked
        ? "Deseja realmente desbloquear o tomador?"
        : "Deseja realmente bloquear o tomador?";
      this.policyHolderId = policyHolderId;
      this.openModalBlockPoliceHolder = true;
      this.justifyBlock = "";
      this.isBlocked = isBlocked;
    },
    closeModalBlock() {
      this.PolicyHolderId = null;
      this.openModalBlockPoliceHolder = false;
    },
    async blockPoliceHolder() {
      if (!this.justifyBlock && !this.isBlocked) {
        this.$onpoint.errorModal("Justificativa obrigatória");
        return;
      }
      await this.$onpoint.loading(async () => {
        return  await this.blockUnblockPolicyHolder({
          id: this.policyHolderId,
          justify: this.justifyBlock
        })
          .then(resp => {
            const action = this.isBlocked ? "desbloqueado" : "bloqueado";
            this.carregarCorretora();
            this.$vs.notify({
              title: "Sucesso",
              text: `Tomador ${action} com sucesso!`,
              color: "success"
            });
            this.openModalBlockPoliceHolder = false;
          })
          .catch(err => {
            this.$onpoint.errorModal(err.response.data.Errors);
          });
      });
    },
    async carregarCorretora() {
      if (
        this.bDocumentacaoPendente == undefined ||
        this.bDocumentacaoPendente == true
      ) {
        await this.$onpoint.loading(async () => {
          return await this.getCorretora()
            .then(response => {
              this.modalDocuments = this.bDocumentacaoPendente;

              if (!this.bDocumentacaoPendente) return this.listarTomadores();
            })
            .catch(errors => {
              this.$onpoint.errorModal(errors.response.data.Errors);
            });
        });
      } else {
        return await this.listarTomadores();
      }
    },

    async listarTomadores(resetPage) {
      this.filtro = !this.filtro;
      if (resetPage) this.currentPage = 1;

      const params = {
        filter: {
          ...this.filter,
          policyHolderDocument: Utils.removeSpecialChars(
            this.filter.policyHolderDocument
          )
        },
        currentPage: this.currentPage,
        rowsPerPage: this.rowsPerPage
      };

      await this.$onpoint.loading(async() => {
        return await this.searchTomadores(params).catch(err => {
          this.$onpoint.errorModal(err.response.Message);
        });
      });
    },

    limparFiltros() {
      this.filter = {
        policyHolderName: "",
        policyHolderDocument: ""
      };
      this.currentPage = 1;
      this.listarTomadores();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/mixins";

.con-vs-popup {
  z-index: 52000;
}

.padding-left-20 {
  padding-left: 20px;
}

.table-list {
  margin-top: -32px;

  ::v-deep .vs-table--thead {
    tr {
      background: transparent !important;
    }
  }

  ::v-deep .vs-table--td {
    padding: 31px 15px !important;
    margin-bottom: 1em;
  }

  ::v-deep .vs-table--search {
    max-width: initial;
    margin-bottom: 20px;
    i {
      font-size: 2em;
      margin-top: 30px;

      @include md {
        margin-top: -24px;
      }
    }

    input {
      padding-left: 40px !important;
      font-size: 1.4em !important;
      width: 300px;
      border: 0 !important;
      height: 47px;
      margin-top: 30px;

      @include md {
        margin-top: -24px;
      }
    }
  }

  ::v-deep .tr-values {
    margin-bottom: 5px;
    border-left: solid 8px rgba(var(--vs-warning), 1);
    border-bottom: solid 10px #f8f8f8;
  }
}

.icon-dots-three-vertical::before{
  position: relative;
  top: 2px;
  font-size: 24px;
  color: #afafaf;
}
</style>
